import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRaf } from 'react-use'
import { secondsToMinutes } from '../../../helpers/formatting'
import { getTotalSecondsBeforeExpiration } from '../../../helpers/orders'
import { Order } from '../../../store/order/types'
import { getServerTimeDelayMs } from '../../../store/ws/selectors'
import { isPending } from '../helpers'

import styles from '../grid.module.scss'

interface Props {
  order: Order
}

const OrderPendingCountdown = ({ order }: Props) => {
  const serverTimeDelayMs = useSelector(getServerTimeDelayMs)
  const { totalSeconds, secondsLeft } = getTotalSecondsBeforeExpiration(
    order.expiration,
    order.submitTime,
    serverTimeDelayMs
  )

  const actualSeconds = isPending(order) ? totalSeconds : 0

  // force the component to update as the countdown changes
  const elapsed = useRaf(actualSeconds * 1000, 1000)

  const formattedTime = useMemo(() => {
    return secondsToMinutes(secondsLeft)
  }, [elapsed])

  if (!actualSeconds) return <></>

  return <span className={styles.countdown}>{formattedTime}</span>
}

export default OrderPendingCountdown
