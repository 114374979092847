import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { getOrderTypeFromResponse } from '../../../store/order/helpers'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import {
  deselectSecurityOrdersToCancel,
  selectSecurityOrdersToCancel,
  toggleCancelSubOrderSelection
} from '../../../store/listTrading/actions'
import { getOrdersSelectedForCancel } from '../../../store/listTrading/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import {
  getHedgeOrder,
  getListTradeError,
  getListTradeOrders,
  getListTradeOrdersForSecurity
} from '../../../store/order/selectors'
import { Order } from '../../../store/order/types'
import { getDisplayStatus, isPending } from '../helpers'

type StatusTuple = ['Traded' | 'Pending' | 'Error' | 'Crossed', number]

export const useListTradingAggressOrders = (
  securityId?: ListTradingSecurity['id'],
  isBid?: boolean | null,
  watchlistId?: number
) => {
  const dispatch = useAppDispatch()
  const getAggressOrders = useAppSelector(getListTradeOrders)
  const getTradedOrders = useAppSelector(getListTradeOrdersForSecurity)
  const getSelectedOrders = useAppSelector(getOrdersSelectedForCancel)
  const getTreasuryHedge = useAppSelector(getHedgeOrder)
  const getError = useAppSelector(getListTradeError)

  const oppositeType = isBid ? 'buy' : 'sell'

  const { orders, hedgeOrders } = useMemo<{
    orders: Order[]
    hedgeOrders: Order[]
  }>(() => {
    if (!securityId || !watchlistId) {
      return {
        orders: [] as Order[],
        hedgeOrders: [] as Order[]
      }
    }
    const aggressOrders = getAggressOrders(
      securityId,
      oppositeType,
      watchlistId
    )
    const currentOrders = aggressOrders.filter((order) => {
      const orderTime = order.tradeTime || order.submitTime
      return dayjs(orderTime).isSame(dayjs(), 'day')
    })
    const hos = currentOrders
      .map((order) => getTreasuryHedge(order.id))
      .filter((order) => !!order)
    return { orders: currentOrders, hedgeOrders: hos }
  }, [getAggressOrders])

  const toggleOrderCancel = useCallback(
    (orderId: string) => {
      if (securityId) {
        dispatch(toggleCancelSubOrderSelection(securityId, orderId))
      }
    },
    [dispatch]
  )

  const selectOrDeselectAllSecurityOrders = useCallback(
    (select: boolean) => {
      if (!securityId || !watchlistId) return
      if (select) {
        const selectOrders = getAggressOrders(
          securityId,
          oppositeType,
          watchlistId
        )
          .filter(isPending)
          .map((order) => order.id)
        dispatch(selectSecurityOrdersToCancel(securityId, selectOrders))
      } else {
        dispatch(deselectSecurityOrdersToCancel(securityId))
      }
    },
    [dispatch, getAggressOrders, oppositeType]
  )

  const orderSummary = useMemo<StatusTuple[]>(() => {
    if (!securityId || !watchlistId) return []
    const aggressOrders =
      getAggressOrders(securityId, oppositeType, watchlistId) ?? []
    const initialOrders = getTradedOrders(securityId) ?? []
    const initialIds = initialOrders.map((o) => o.id)

    const thisType = isBid ? 'sell' : 'buy'

    const passiveStatuses = initialOrders
      .map((o) =>
        // @ts-ignore
        typeof o.type === 'number'
          ? { ...o, type: getOrderTypeFromResponse(o.type) }
          : o
      )
      .filter((o) => o.type === thisType)
      .reduce(
        (statuses, order) => {
          const [traded, pending, crossed, error] = statuses
          if (getError(order.id)) {
            return [
              traded,
              pending,
              crossed,
              [error[0], error[1] + 1] as StatusTuple
            ]
          }

          const aggressOrder = aggressOrders.find(
            (o) => o.initialOrder?.id === order.id
          )
          if (!aggressOrder) return statuses
          switch (getDisplayStatus(aggressOrder.status)) {
            case 'Traded':
              return [
                [traded[0], traded[1] + 1] as StatusTuple,
                pending,
                crossed,
                error
              ]
            case 'Pending':
              return [
                traded,
                [pending[0], pending[1] + 1] as StatusTuple,
                crossed,
                error
              ]
            default:
              return statuses
          }
        },
        [
          ['Traded', 0],
          ['Pending', 0],
          ['Crossed', hedgeOrders.length],
          ['Error', 0]
        ] as StatusTuple[]
      )

    const otherAggressOrders = aggressOrders.filter(
      (agg) =>
        !initialIds.includes(agg.initialOrder?.id ?? 'not an id') &&
        dayjs().isSame(agg.tradeTime ?? agg.submitTime, 'day')
    )

    return otherAggressOrders
      .reduce((statuses, aggressOrder) => {
        const [traded, pending, crossed, error] = statuses

        switch (getDisplayStatus(aggressOrder.status)) {
          case 'Traded':
            return [
              [traded[0], traded[1] + 1] as StatusTuple,
              pending,
              crossed,
              error
            ]
          case 'Pending':
            return [
              traded,
              [pending[0], pending[1] + 1] as StatusTuple,
              crossed,
              error
            ]
          default:
            return statuses
        }
      }, passiveStatuses)
      .filter((status) => status[1])
  }, [
    securityId,
    oppositeType,
    getAggressOrders,
    getListTradeError,
    getTradedOrders,
    hedgeOrders
  ])

  if (!watchlistId || !securityId) {
    return {
      hasPendingOrders: false,
      orders: [] as Order[],
      hedgeOrders: [] as Order[],
      selectedOrderIds: [] as string[],
      toggleOrderCancel: (_orderId: string) => {
        /* intentionally left blank */
      },
      selectOrDeselectAllSecurityOrders: () => {
        /* intentionally left blank */
      },
      orderSummary
    }
  }

  const selectedOrderIds = getSelectedOrders(securityId)
  const hasPendingOrders = orders.some(isPending)

  return {
    hasPendingOrders,
    orders,
    hedgeOrders,
    orderSummary,
    selectedOrderIds,
    toggleOrderCancel,
    selectOrDeselectAllSecurityOrders
  }
}
