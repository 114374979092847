import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/types'
import {
  cancelListTradingOrders,
  selectListTradingSecurities,
  tradeSelectedSecurities
} from '../../store/listTrading/actions'
import {
  getCountOfOrdersToCancel,
  getSelectedRowCount,
  getSelectedTradeCount
} from '../../store/listTrading/selectors'
import { WatchList } from '../../store/watchList/types'
import styles from './styles.module.scss'

interface Props {
  watchlist?: WatchList
}

const TradingListHeader = ({ watchlist }: Props) => {
  const selectedRowCount = useAppSelector(getSelectedRowCount)
  const selectedTradeCount = useAppSelector(getSelectedTradeCount)

  const s = selectedTradeCount > 1 ? 's' : ''
  const label = `Submit ${selectedTradeCount} Trade${s}`

  const selectedForCancelCount = useAppSelector(getCountOfOrdersToCancel)
  const cancelS = selectedForCancelCount > 1 ? 's' : ''
  const cancelLabel = `Cancel ${selectedForCancelCount} Aggress Attempt${cancelS}`

  const dispatch = useAppDispatch()
  const submitTrades = useCallback(() => {
    if (watchlist?.id) {
      dispatch(tradeSelectedSecurities(watchlist.id))
      dispatch(selectListTradingSecurities([]))
    }
  }, [watchlist?.id])
  const cancelTrades = useCallback(() => {
    if (watchlist?.id) {
      dispatch(cancelListTradingOrders(watchlist.id))
    }
  }, [watchlist?.id])
  return (
    <div className={styles.controls}>
      <h2>{watchlist?.name}</h2>
      <div>
        {selectedForCancelCount > 0 && (
          <button
            id="cancel-selected-trades"
            data-testid="cancel-selected-trades"
            onClick={cancelTrades}
          >
            {cancelLabel}
          </button>
        )}
        {selectedRowCount > 0 && (
          <button
            id="submit-selected-trades"
            data-testid="submit-selected-trades"
            onClick={submitTrades}
          >
            {label}
          </button>
        )}
      </div>
    </div>
  )
}

export default TradingListHeader
