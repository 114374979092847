import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBan,
  faCircleMinus
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import IconCheckbox from './IconCheckbox'

interface Props {
  onChange: (value: boolean) => void
  className?: string
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  testId?: string
  title?: string
}

const defaultIcon = () => <FontAwesomeIcon icon={faBan} />
const indeterminateIcon = () => <FontAwesomeIcon icon={faCircleMinus} />
const BanCheck = (props: Props) => {
  return (
    <IconCheckbox
      defaultIcon={defaultIcon}
      indeterminateIcon={indeterminateIcon}
      {...props}
    />
  )
}

export default BanCheck
