import { ListTradingSecurity } from '../../store/listTrading/types.ts'
import { Order, OrderStatus } from '../../store/order/types'

export const isGoodTrade = (
  myAmount: number,
  theirAmount: number | undefined,
  isBid: boolean,
  isSpread: boolean
) => {
  if (typeof theirAmount !== 'number') return false
  const bidMultiplier = isBid ? 1 : -1
  const spreadMultiplier = isSpread ? -1 : 1
  return (myAmount - theirAmount) * bidMultiplier * spreadMultiplier > 0
}

export const isEmpty = (value: any): value is null | undefined =>
  value === undefined || value === null

export const isTradeable = (security: ListTradingSecurity) => {
  if (!security.remainingInterest || security.remainingInterest <= 0) {
    return false
  }
  const bestTrade = security.bestOrder
  if (!bestTrade) return false
  const myAmount = security.isSpread
    ? security.targetSpread
    : security.targetPrice
  const theirAmount = security.isSpread ? bestTrade.spread : bestTrade.price
  if (isEmpty(security.isBid)) return false
  if (isEmpty(myAmount) && (bestTrade.price || bestTrade.spread)) return true
  if (isEmpty(myAmount)) return false
  return isGoodTrade(myAmount, theirAmount, security.isBid, security.isSpread)
}
export const isPending = ({ status }: Order) =>
  ['pending', 'waitingForConfirmation'].includes(status)
export const getDisplayStatus = (status?: OrderStatus) => {
  if (!status) return ''
  if (status === 'accepted') return 'Traded'
  if (status === 'waitingForConfirmation') return 'Pending'
  const capitalLetters = /[A-Z]/g
  const spaced = status.replace(capitalLetters, (replaced) => ' ' + replaced)
  if (spaced.length < 2) return spaced
  const [initial, ...rest] = spaced
  return initial.toUpperCase() + rest.join('')
}

export const toggleSwitch = 'pretty p-toggle p-switch p-smooth'
