import { CustomDetailCellRendererProps } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { memo, MouseEvent, useCallback, useMemo } from 'react'
import AggressOrdersTable from '../../containers/TradingList/cells/AggressOrdersTable'
import { isEmpty, isGoodTrade } from '../../containers/TradingList/helpers'
import { useGetListTradingSecurity } from '../../containers/TradingList/hooks/useGetListTradingSecurity.tsx'
import { useWorkingListTradingOrder } from '../../containers/TradingList/hooks/useWorkingListTradingOrder'
import { toggleSuborderSelection } from '../../store/listTrading/actions'
import { Order } from '../../store/order/types'
import { SecurityStaticData } from '../../store/securities/reducer.ts'
import { useAppDispatch } from '../../store/types'
import styles from './Depth/DepthOfMarket.module.scss'
import DepthOfMarket from './Depth/DepthOfMarket.tsx'
import InlineDepthHeader from './Depth/InlineDepthHeader.tsx'
import configureSelectOrder from './Depth/SelectOrder.tsx'

const ListTradingDepthDetailRenderer = ({
  data,
  context,
  pinned
}: CustomDetailCellRendererProps<SecurityStaticData>) => {
  const dispatch = useAppDispatch()

  const security = data
  const ltSecurity = useGetListTradingSecurity(security?.id ?? -1)

  const { order } = useWorkingListTradingOrder(
    context.watchlistId,
    security?.id
  )
  const selectedOrders = order?.selectedOrders || []

  const onOrderToggle = useCallback((orderId: string) => {
    if (!security) return
    dispatch(toggleSuborderSelection(security.id, orderId))
  }, [])
  const checkIsSelected = useCallback(
    (orderId: string) => {
      if (!ltSecurity) return false
      return selectedOrders.includes(orderId)
    },
    [selectedOrders, security]
  )

  const preventRightClickMenu = useCallback((e: MouseEvent) => {
    e.stopPropagation()
  }, [])

  const extraColumns = useMemo(() => {
    if (!ltSecurity) return []
    const disableFn = (o: Order) => {
      const enabledByType =
        o.canAggress && ltSecurity.isBid ? o.type === 'sell' : o.type === 'buy'
      if (
        !enabledByType ||
        !ltSecurity.remainingInterest ||
        isEmpty(ltSecurity.isBid)
      ) {
        return true
      }
      const myAmount = ltSecurity.isSpread
        ? ltSecurity.targetSpread
        : ltSecurity.targetPrice
      if (isEmpty(ltSecurity.isSpread) || isEmpty(myAmount)) {
        return false
      }
      const theirAmount = ltSecurity.isSpread ? o.spread : o.price

      return !isGoodTrade(
        myAmount,
        theirAmount,
        ltSecurity.isBid,
        ltSecurity.isSpread
      )
    }
    const SelectOrderColumn = configureSelectOrder({
      onOrderToggle,
      checkedFn: checkIsSelected,
      disableFn
    })
    return [SelectOrderColumn]
  }, [ltSecurity, checkIsSelected])

  if (!security) return <></>
  if (!pinned) {
    return (
      <div
        onContextMenu={preventRightClickMenu}
        onContextMenuCapture={preventRightClickMenu}
        className={styles.ltSecuritySection}
      >
        <DepthOfMarket
          gridIndex={0}
          security={security}
          isPopout={false}
          className={cx(styles.depthOfMarket)}
          extraColumns={extraColumns}
          listId={context.watchlistId}
        >
          <InlineDepthHeader security={security} />
        </DepthOfMarket>
      </div>
    )
  }
  if (pinned === 'right' && ltSecurity) {
    return (
      <AggressOrdersTable
        ltSecurity={ltSecurity}
        security={security}
        watchlistId={context.watchlistId}
        showDetails={context.showOrderDetails}
        currency={security.currency}
      />
    )
  }
  return <></>
}

export default memo(ListTradingDepthDetailRenderer)
