import {
  GetRowIdFunc,
  GridApi,
  IRowNode,
  RowGroupOpenedEvent
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'
import OrderTooltip from '../../containers/BondList/OrderTooltip'
import SecurityDetailsModalLazy from '../../containers/DepthOfMarket/DetailsModal/SecurityDetailsModalLazy'
import useAgGridLoading from '../../helpers/hooks/useAgGridLoading'

import { getOrdersFetched } from '../../store/order/selectors'
import { fetchSecurities, setCurrentPage } from '../../store/securities/actions'
import { Security, SecurityStaticData } from '../../store/securities/reducer'
import {
  getCurrentPage,
  getSortToTop,
  getStaticDataForPage,
  getWatchlistId,
  hasError,
  isPending
} from '../../store/securities/selectors'
import DepthDetailRenderer from '../DepthOfMarket/DepthDetailRenderer'

import styles from './grid.module.scss'

import { GridRef, Props } from './types'

import { useGridControls } from './useGridControls'

export const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  suppressNavigable: true
}

const sortFunction = (row1: Security, row2: Security) => {
  const row1Best = row1.bestBid?.myFirm || row1.bestOffer?.myFirm
  const row2Best = row2.bestBid?.myFirm || row2.bestOffer?.myFirm
  if (row1Best && !row2Best) {
    return -1
  }
  if (row2Best && !row1Best) {
    return 1
  }

  if (row1.issuerSymbol > row2.issuerSymbol) {
    return 1
  } else if (row1.issuerSymbol < row2.issuerSymbol) {
    return -1
  }

  if (row1.maturityDate > row2.maturityDate) {
    return 1
  } else if (row1.maturityDate < row2.maturityDate) {
    return -1
  }

  if (row1.coupon > row2.coupon) {
    return 1
  } else if (row1.coupon < row2.coupon) {
    return -1
  } else {
    return 0
  }
}

const getRowId: GetRowIdFunc<Security> = ({ data }) => {
  return `${data?.id ?? ''}`
}

const components = {
  orderTooltip: OrderTooltip
}

const MineGrid = forwardRef<GridRef, Props>(
  (
    {
      gridIndex,
      onSelectionChanged,
      onRowDoubleClicked,
      canEditWatchlist,
      setRowsAreOpen
    },
    handle
  ) => {
    const dispatch = useDispatch()
    const loadingSecurities = useSelector(isPending)(gridIndex)
    const error = useSelector(hasError)(gridIndex)
    const currentPage = useSelector(getCurrentPage)(gridIndex)
    // this will return a new function every time one of the functions in its dependency list changes
    const getPageOfStaticSecurities = useSelector(getStaticDataForPage)
    const watchlistId = useSelector(getWatchlistId)(gridIndex)

    const [gridApi, setGridApi] = useState<{
      api: GridApi
    } | null>(null)

    const [selectedDetailSecurityId, setSelectedDetailSecurityId] =
      useState<number>(0)

    const rowStyle = useMemo(() => {
      return {
        borderTop: 0,
        borderBottom: 'none'
      }
    }, [])

    const sortToTop = useSelector((state) =>
      getSortToTop(state as any)(gridIndex)
    )
    const [rowDataState, setRowDataState] = useState<SecurityStaticData[]>([])
    useDebounce(
      () => {
        let rowData =
          getPageOfStaticSecurities(gridIndex, currentPage ?? 0) || []
        const sortedToTopTemp = []
        const rowDataTemp = []
        for (const s of rowData) {
          let sorted = false
          for (const stt of sortToTop) {
            if (s?.id === stt) {
              sortedToTopTemp.push(s)
              sorted = true
              break
            }
          }
          if (!sorted) {
            rowDataTemp.push(s)
          }
        }
        const sortedToTop = sortedToTopTemp.sort(sortFunction)
        rowData = rowDataTemp.sort(sortFunction)
        setRowDataState(sortedToTop.concat(rowData))
      },
      200,
      [getPageOfStaticSecurities, gridIndex, currentPage, sortToTop]
    )

    const {
      handleColumnChange,
      getContextMenuItems,
      columnDefs,
      handleGridRowClick,
      handleGridRowDoubleClick,
      getRowClass,
      gridContext,
      rowSelection,
      theme
    } = useGridControls({
      gridApi,
      gridIndex,
      canEditWatchlist,
      setSelectedDetailSecurityId,
      currentPage,
      onClickGridRow: onSelectionChanged,
      onDoubleClickGridRow: onRowDoubleClicked
    })

    useEffect(() => {
      gridApi?.api.redrawRows()
    }, [])

    const onGridReady = useCallback(({ api }: { api: GridApi }) => {
      if (!gridApi) {
        setGridApi({ api })
      }
    }, [])

    const ordersAreFetched = useSelector(getOrdersFetched)
    useEffect(() => {
      if (ordersAreFetched) {
        if (currentPage === 0) {
          dispatch(fetchSecurities(gridIndex, 0))
        } else {
          dispatch(setCurrentPage(gridIndex, 0))
        }
      }
    }, [watchlistId, ordersAreFetched, currentPage])

    useAgGridLoading(
      rowDataState,
      loadingSecurities && currentPage === 0,
      error,
      gridApi?.api
    )

    // ------------------ collapsing/expanding rows  ------------------ //
    const onRowExpandedChange = useCallback((e: RowGroupOpenedEvent) => {
      let expandedCount = 0
      e.api.forEachNode((node: IRowNode<SecurityStaticData>) => {
        expandedCount += Number(node?.expanded ?? 0)
      })
      setRowsAreOpen(!!expandedCount)
      const rowNodes = [e.node]
      e.api.redrawRows({ rowNodes })
    }, [])
    useImperativeHandle(
      handle,
      () => {
        // for some reason gridRef is null if you access it directly in the fn
        const api = gridApi?.api
        return {
          collapseRows: () => {
            api?.forEachNode((node: IRowNode<SecurityStaticData>) =>
              node.setExpanded(false)
            )
          }
        }
      },
      [gridApi?.api]
    )

    if (!ordersAreFetched || !columnDefs) {
      // When MINE is checked, we wait for the orders to be fetched before fetching
      // securities for the first time.
      return null
    }

    return (
      <React.Fragment>
        <div
          className={`${theme} ${styles.gridStyle} ${styles.mainGrid}`}
          data-testid={`securities-grid-${gridIndex}`}
        >
          <AgGridReact
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            embedFullWidthRows={true}
            detailCellRenderer={DepthDetailRenderer}
            detailRowHeight={170}
            onRowGroupOpened={onRowExpandedChange}
            suppressRowTransform={true}
            suppressColumnVirtualisation={true}
            columnMenu="legacy"
            columnDefs={columnDefs}
            defaultColDef={defaultColumnDefinitions}
            suppressScrollOnNewData={true}
            components={components}
            groupHeaderHeight={0}
            headerHeight={20}
            rowHeight={20}
            rowStyle={rowStyle}
            getRowClass={getRowClass}
            rowData={rowDataState}
            rowSelection={rowSelection}
            onRowClicked={handleGridRowClick}
            onRowDoubleClicked={handleGridRowDoubleClick}
            onGridReady={onGridReady}
            getRowId={getRowId}
            enableCellTextSelection={true}
            singleClickEdit={true}
            suppressNoRowsOverlay={!gridApi}
            suppressMenuHide={true}
            overlayNoRowsTemplate={
              error
                ? 'An error occurred during securities update.'
                : 'No security found for current filters and watchlist.'
            }
            overlayLoadingTemplate="Loading securities…"
            alwaysShowVerticalScroll={true}
            suppressDragLeaveHidesColumns={true}
            onColumnMoved={handleColumnChange}
            onDisplayedColumnsChanged={handleColumnChange}
            tooltipShowDelay={0}
            enterNavigatesVerticallyAfterEdit={true}
            context={gridContext}
          />
        </div>
        {!!selectedDetailSecurityId && (
          <SecurityDetailsModalLazy
            securityId={selectedDetailSecurityId}
            isOpen={!!selectedDetailSecurityId}
            toggleIsOpen={() => setSelectedDetailSecurityId(0)}
          />
        )}
      </React.Fragment>
    )
  }
)

export default memo(MineGrid)
