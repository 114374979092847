import {
  faArrowsCross,
  faCircleInfo
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import dayjs from 'dayjs'
import React, { memo, useMemo } from 'react'
import { formatSpread } from '../../../components/Activity/AlertDescription/helpers'
import styles from '../../../components/DepthOfMarket/Depth/DepthOfMarket.module.scss'
import {
  formatCurrency,
  formatSize,
  formatTreasuryPrice
} from '../../../helpers/formatting'
import { getMarketForSecurity } from '../../../store/depthOfMarket/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { getListTradeError } from '../../../store/order/selectors'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { useAppSelector } from '../../../store/types'
import { WatchList } from '../../../store/watchList/types'
import { getDisplayStatus, isPending } from '../helpers'
import { useListTradingAggressOrders } from '../hooks/useListTradingAggressOrders'
import OrderPendingCountdown from './OrderPendingCountdown'
import SelectAggressOrderToCancelCheckbox from './SelectAggressOrderToCancelCheckbox'

import { Order } from '../../../store/order/types'
import tableStyles from '../grid.module.scss'

interface Props {
  ltSecurity: ListTradingSecurity
  security: SecurityStaticData
  watchlistId: WatchList['id']
  showDetails: (orderId: string | null) => void
  currency?: string
}

const getDirection = (order: Order) => {
  if (order.status === 'accepted') {
    return order.type === 'buy' ? 'Bought' : 'Sold'
  }
}

const AggressOrdersTable = ({
  ltSecurity,
  security,
  watchlistId,
  showDetails,
  currency
}: Props) => {
  const {
    orders: aggressOrders,
    hedgeOrders,
    selectedOrderIds,
    toggleOrderCancel
  } = useListTradingAggressOrders(ltSecurity.id, ltSecurity.isBid, watchlistId)

  const getError = useAppSelector(getListTradeError)
  const depthOrders = useAppSelector(getMarketForSecurity)(
    0,
    ltSecurity.id,
    ltSecurity.isBid ? 'buy' : 'sell'
  )
  const bestTrade = ltSecurity.bestOrder

  const errRows = useMemo(() => {
    const error = bestTrade ? getError(bestTrade.id) : undefined
    const best = { ...bestTrade, error }
    const errors = depthOrders.map((order) => ({
      ...order,
      error: getError(order.id)
    }))
    if (best?.error) {
      // @ts-ignore
      errors.unshift(best)
    }
    return errors.filter((err) => err.error)
  }, [getError, depthOrders, bestTrade])

  return (
    <table
      className={cx(styles.ltStatus, tableStyles.aggressOrderTable)}
      data-testid={`listTrading-aggress-table-${ltSecurity.id}`}
    >
      <tbody>
        {aggressOrders.map((aggressOrder) => {
          const status = getDisplayStatus(aggressOrder?.status)
          const spreadAmt = ltSecurity.isSpread
            ? `${formatSpread(aggressOrder.spread ?? 0)} | `
            : ''
          const amount =
            spreadAmt + formatCurrency(aggressOrder.price, currency)
          const orderTime = aggressOrder.tradeTime || aggressOrder.submitTime
          return (
            <tr key={aggressOrder.id}>
              <td>
                <SelectAggressOrderToCancelCheckbox
                  onChange={toggleOrderCancel}
                  isChecked={selectedOrderIds.includes(aggressOrder.id)}
                  orderId={aggressOrder.id}
                  enabled={isPending(aggressOrder)}
                />
              </td>
              <td>
                {isPending(aggressOrder) ? (
                  <OrderPendingCountdown order={aggressOrder} />
                ) : (
                  getDirection(aggressOrder)
                )}
              </td>
              <td>
                {formatSize(
                  aggressOrder.size,
                  aggressOrder.totalSize,
                  false,
                  false
                )}
              </td>
              <td>{amount}</td>
              <td>{status}</td>
              <td>{orderTime ? dayjs(orderTime).format('h:mmA') : ''}</td>
              <td>
                {status === 'Traded' && (
                  <div
                    data-testid={`listTrading-aggress-table-${ltSecurity.id}-${aggressOrder.id}-info`}
                    onClick={() => showDetails(aggressOrder.id)}
                    title="View Trade Confirm"
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </div>
                )}
              </td>
            </tr>
          )
        })}
        {hedgeOrders.map((row) => {
          const amount = formatTreasuryPrice(row.price)
          const status = getDisplayStatus(row.status)
          return (
            <tr key={row.id}>
              <td>
                <FontAwesomeIcon icon={faArrowsCross} />
              </td>
              <td>{getDirection(row)}</td>
              <td>{formatSize(row.size, row.totalSize, false, false)}</td>
              <td className={tableStyles.hedgeInfo}>
                <span>{security.benchmarkName}</span>
                {amount}
              </td>
              <td>{status}</td>
              <td>
                {row.tradeTime ? dayjs(row.tradeTime).format('h:mmA') : ''}
              </td>
              <td>
                {status === 'Traded' && (
                  <div
                    data-testid={`listTrading-aggress-table-${ltSecurity.id}-${row.id}-info`}
                    onClick={() => showDetails(row.id)}
                    title="View Trade Confirm"
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </div>
                )}
              </td>
            </tr>
          )
        })}
        {errRows.map((row) => {
          const amount = row.isSpreadOrder
            ? formatSpread(row.spread ?? 0)
            : formatCurrency(row.price, currency)
          return (
            <tr key={row.id} className={tableStyles.error}>
              <td></td>
              <td>Failed</td>
              <td>
                {formatSize(
                  row.error?.size ?? row.size,
                  row.totalSize,
                  false,
                  false
                )}
              </td>
              <td>{amount}</td>
              <td title={row.error?.error.message}>
                Error: {row.error?.error.message}
              </td>
              <td></td>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default memo(AggressOrdersTable)
