import React, { useCallback } from 'react'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'
import { BuySellSwitch } from './ToggleSwitch'
import { WorkingOrderFieldRendererProps } from './WorkingOrderFieldRenderer'

const BuySellToggleRenderer = ({
  data,
  watchlistId
}: WorkingOrderFieldRendererProps) => {
  const { order, updateField } = useWorkingListTradingOrder(
    watchlistId,
    data?.id,
    data?.isin,
    true
  )
  const onSwitchChange = useCallback((value) => {
    updateField('isBid', value)
  }, [])
  if (!data) return <></>
  return (
    <BuySellSwitch
      securityId={data.id}
      isBid={order.isBid}
      onChange={onSwitchChange}
    />
  )
}

export default BuySellToggleRenderer
