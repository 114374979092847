import React, { useCallback } from 'react'
import BanCheck from '../../../components/IconCheckbox/BanCheck'

interface Props {
  onChange: (orderId: string) => void
  isChecked: boolean
  orderId: string
  enabled?: boolean
}

const SelectAggressOrderToCancelCheckbox = ({
  onChange,
  isChecked,
  orderId,
  enabled
}: Props) => {
  const onValueChange = useCallback(() => {
    onChange(orderId)
  }, [orderId])
  return (
    <BanCheck
      checked={isChecked}
      onChange={onValueChange}
      disabled={!enabled}
      testId={`select-${orderId}-listTrading-order-cancel-checkbox`}
      title="Select trade to cancel"
    />
  )
}

export default SelectAggressOrderToCancelCheckbox
