import { CustomCellEditorProps } from '@ag-grid-community/react'
import React, { ChangeEvent, useCallback } from 'react'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { TradingListContext } from '../Grid'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'
import { useManageGridCellFocus } from './useManageGridCellFocus'

const SizeEditor = ({
  data,
  context
}: CustomCellEditorProps<SecurityStaticData, number, TradingListContext>) => {
  const { order, updateField } = useWorkingListTradingOrder(
    context.watchlistId,
    data?.id,
    data?.isin
  )
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newVal = e.currentTarget.value
      updateField('size', Number(newVal))
    },
    [order, updateField]
  )

  const inputRef = useManageGridCellFocus()

  return (
    <input
      ref={inputRef}
      className="ag-input-field-input ag-text-field-input"
      type="number"
      id={`listtrading-edit-size-${data.id}`}
      data-testid={`listtrading-edit-size-${data.id}`}
      onChange={onChange}
    />
  )
}

export default SizeEditor
